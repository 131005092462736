<script>
import { required, email } from "vuelidate/lib/validators";

import {
  authMethods,
  authFackMethods,
  notificationMethods
} from "@/state/helpers";

export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
  }
};
</script>

<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-dark.png" height="30" alt="logo" />
                            Antar Port
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Success !</h4>
                        <p class="text-muted">Thank you for joining Antar Port</p>
                      </div>

                      <b-alert
                        variant="danger"
                        class="mt-3"
                        v-if="notification.message"
                        show
                        dismissible
                      >{{notification.message}}</b-alert>

                      <div class="p-2 mt-5">
                        <!-- <form class="form-horizontal" @submit.prevent="tryToLogIn"> -->

                          <!--<div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                            >Remember me</label>
                          </div>-->

                          <div class="mt-4 text-center">
                          <a href="https://antarport.id"
                              class="btn btn-primary w-md waves-effect waves-light mr-3"
                              >Kembali</a>
                          <a href="https://account.antarport.id"
                              class="btn btn-primary w-md waves-effect waves-light"
                            >Log In</a>
                          </div>
                        <!-- </form> -->
                      </div>

                      <div class="mt-5 text-center">
                        <!--<p>
                          Don't have an account ?
                          <router-link
                            tag="a"
                            to="/register"
                            class="font-weight-medium text-primary"
                          >Register</router-link>
                        </p>-->
                        <p>
                          Powered by DAC Group and Odisys
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
              <div class="register-bg">
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
